import { ClipLoader } from "react-spinners";
import "./App.css";
import { OrgChart } from "./org-tree/orgChart.tsx";
import { convertDeptsToTree } from "./utils/util.js";
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function OrgTree() {
  // set depts to null initially
  const [depts, setDepts] = useState(null);

  // get the organization chart data from the server (only once)
  useEffect(() => {
    fetch("/organization-chart")
      .then((response) => response.json())
      .then((data) => {
        // we set the departments to the array of department objects
        setDepts(data.organization.departments);
      });
  }, []);


  const fetchFile = (file) => {
    const reader = new FileReader();
    reader.readAsText(file.target.files[0]);
    reader.onload = () => {
      setDepts(null);
      // post the CSV data to the server to convert into a department tree
      fetch("/organization-chart", {
        method: "POST",
        body: JSON.stringify({organizationData: reader.result}),
      })
        .then((response) => response.json())
        .then((data) => {
          // once we receive it, we then convert it to a tree that can work with the org-chart library
          setDepts(data.organization.departments);
        });
    }
  }

  return (
    <div className="App">
      <div className="upload">
        <h4>
        Show a custom organization chart by uploading a CSV file:
        </h4>
        <p>
          Nodes can also be expanded and collapsed by clicking on them!
        </p>
        <input type="file" accept=".csv" onChange={fetchFile}/>
      </div>
      {/* if depts is null, then we are still loading the data */}
      {/* center loader in the middle */}
      <div style={{ top: "50%", left: "50%", position: "fixed"}}>
        {!depts && <ClipLoader color="#000000"/>}
      </div>
      {depts && <OrgChart tree={convertDeptsToTree(depts)} />}
    </div>
  );
}

function App() {
  // make the above component only accessible from /orgchart route
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/orgchart" element={<OrgTree />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
