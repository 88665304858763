// note: we put the util functions in the src directory so that we can test them 
// using jest. If we put them in the functions directory, we would not be able to
/**
 * Converts KVs into the representative map of departments.
 * @param {*} rawKVs the object containing the list of KV pairs to convert.
 * @returns {Object} the representative map of departments.
 */
export function convertToDepts(rawKVs) {
    // initialize department map, which we will map department name to objects of departments
    const deptMap = {};

    // add each employee to their respective department
    rawKVs.forEach(kv => {
        // employee will be our KV value, which is stored as an object of attributes pertaining to the employee
        const employee = kv.value;
        // if employee not contained already, create dummy data to fill in
        if (!deptMap[employee.department]) {
            deptMap[employee.department] = {name: employee.department, managerName: "", employees: []};
        }

        // if we find a manager, set the manager name
        if (employee.isManager) {
            deptMap[employee.department].managerName = employee.name;
        }

        // add employee to department at the end
        deptMap[employee.department].employees.push(employee);
    });

    // return the values of the map, which is an array of department objects
    return Object.values(deptMap);
}

/**
 * Converts CSV into the representative map of departments.
 * @param {String} rawCSV the string representing the CSV to convert.
 * @returns {{key: string, value: string}[]} the list of KVs, represented as strings.
 */
export function convertCsvToKvs(rawCsv) {
    // split csv into lines that we can process individually
    const lines = rawCsv.split("\n");
    if (lines.length === 0) {
        return [];
    }

    // fields will be the header of the csv, which we will use to map to the values of each employee
    const fields = lines[0].split(",");
    const kvs = [];
    const deptCounts = {};
    
    // skip over first line since it is the header
    lines.slice(1).forEach(line => {
        const values = line.split(",");
        // we fill in the CSV values into an intermediate object, which we then map to the employee object
        const intermediate = {};
        for (var i = 0; i < fields.length; i++) {
            intermediate[fields[i].trim()] = values[i].trim();
        }

        // keep track of index so that we have unique key names for employees in the same department
        const index = deptCounts[intermediate.department] || 0;
        deptCounts[intermediate.department] = index + 1;
        const employeeObj = {};
        Object.entries(intermediate).forEach(kv => {
            let [field, value] = kv;

            // if field ends with a single number then it is a list field that needs to be appended
            if (field.match(/\d$/)) {
                // pluralize the field name
                if (!employeeObj[field.substring(0, field.length - 1)+"s"]) {
                    employeeObj[field.substring(0, field.length - 1)+"s"] = [];
                }

                employeeObj[field.substring(0, field.length - 1)+"s"].push(value);
            } else {
                // set field value, with proper type if needed
                if (field === "salary") {
                    value = parseInt(value);
                } else if (field === "isManager") {
                    value = value.trim().toLowerCase() === "true";
                }
                employeeObj[field] = value;
            }
        });

        // add employee object to the list of kvs
        // our key name is of the format department:index
        kvs.push({key: intermediate.department+":"+index, value: employeeObj})
    });

    return kvs;
}

/**
 * Queries the department map for employees that match the query.
 * @param {Object} deptMap the map of departments containing employees.
 * @param {Object} query the query object containing the filters (regex for strings, numbers for ranges) to apply.
 * @returns {Object[]} the list of employees that match the query.
 */
export function matchEmployees(deptMap, query) {
    const matches = [];
    deptMap.forEach(dept => {
        // check if the department specified matches the query, and if not, skip over it
        if (query.department && !dept.name.match(query.department)) {
            return;
        }

        dept.employees.forEach(employee => {
            // if guards, which check for if any of the conditions are failed if specified
            if (query.minSalary && (employee.salary < query.minSalary)) {
                return;
            }

            if (query.maxSalary && (employee.salary > query.maxSalary)) {
                return;
            }

            if (query.name && !employee.name.match(query.name)) {
                return;
            }

            if (query.office && !employee.office.match(query.office)) {
                return;
            }

            // see if any one of the skills match the skill filter
            if (query.skill && employee.skills.filter(skill => skill.match(query.skill)).length === 0) {
                return;
            }

            // we have reached all conditions if specified, so add employee to matches
            matches.push(employee);
        });
    });

    return matches;
}

/**
 * Converts the department map into a tree structure for the organization chart.
 * @param {Object} deptMap the department map to convert.
 * @returns {Object} the tree structure of the organization chart.
 */
export function convertDeptsToTree(deptMap) {
    const deptTrees = []
    deptMap.forEach(dept => {
        // create a manager entity, which will be the root of the department tree
        const managerEntity = {entity: {}, children: []};
        dept.employees.forEach(employee => {
            // if employee is not a manager, add to children
            if (!employee.isManager) {
                managerEntity.children.push({
                    // specify unique id for when we collapse/expand nodes
                    entity: {
                        id: dept.name + ":" + employee.name,
                        name: employee.name,
                        title: dept.name,
                        office: employee.office + " Office",
                        salary: employee.salary,
                        skills: employee.skills
                    },
                    children: [],
                    id: dept.name + ":" + employee.name,
                });
            } else {
                managerEntity.entity = {
                    id: dept.name + ":" + employee.name,
                    name: employee.name,
                    title: dept.name,
                    office: employee.office + " Office",
                    salary: employee.salary,
                    skills: employee.skills
                };
                managerEntity.id = dept.name + ":" + employee.name;
            }
        });
        deptTrees.push(managerEntity);
    });

    // return the root of the tree, which has a dummy root linking together all the departments
    return {
        entity: {
            name: "Departments",
        },
        children: deptTrees,
        id: "root"
    }
}

export async function getKeyValues(namespace) {
    // get all the keys in the namespace
    const rawKVs = await namespace.list();
    const keys = rawKVs.keys.map((metadata) => metadata.name);
    const kvs = [];
    
    // for each of the keys, we get the value and add it to the list of kvs for processing later on
    for (const key of keys) {
        // since the value stored is literal json, we can preprocess it into json for easy usage
        kvs.push({
            key: key,
            value: await namespace.get(key, {type: "json", cacheTtl: 60*60}) // 1 hour ttl for caching
        })
    }
    return kvs;
}