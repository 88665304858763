/**
 * The following is adapted from https://github.com/scttcper/react-orgchart, which is licensed under MIT.
 * Original implementation by Scott Cooper.
 * Adapted by Ahmed Alcassab (@AhmedA1559) for use in the OrgTree component.
 */
export const getName = data => data.data.entity && data.data.entity.name;

export const getTitle = data => data.data.entity && data.data.entity.title;

export const getOffice = data => data.data.entity && data.data.entity.office;

export const getSalary = data => { 
  if (!data.data.entity || !data.data.entity.salary) {
    return '';
  }
  return `$${data.data.entity.salary}`
};

export const getCount = data => {
  const children = (data.children || []).length || (data._children || []).length;
  if (!children) {
    return '';
  }

  return `${children}`;
};

export const getSkills = data => {
  if (!data.data.entity || !data.data.entity.skills) {
    return '';
  }
  return data.data.entity.skills.join(', ');
};

export const getCursorForNode = data =>
  data.data.children || data.data._children ? 'pointer' : 'default';

export const customOnClick = (fn, onClick, config) => (event, data) => {
  if (typeof fn === 'function') {
    if (fn(data, event)) {
      onClick(config);
    } else {
      event.stopPropagation();
    }
  }
};
